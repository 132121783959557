#addParty {
    width: 70vw;
    height: 62vh;

    .p-dialog-header {
        padding: 15px;
        border-bottom: 1px solid #eeeeee;

        .p-dialog-title {
            font-size: 18px;
        }
    }

    .p-dialog-content {
        padding: 15px;

        .topView {
            display: flex;
            align-items: center;
            gap: 15px;
            flex-wrap: wrap;

            .p-float-label {
                position: relative;

                input {
                    width: 100%;
                }

                i {
                    @media screen and (max-width:991px) {
                        display: none;
                    }
                }
            }
        }

        .p-tabview {
            .p-tabview-panels {
                padding: 1.25rem 0;

                .p-tabview-panel {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .gst-address-flex {
                        display: flex;
                        gap: 15px;

                        .inputfield {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            .p-float-label {

                                .p-inputtext {
                                    width: 100%;
                                }
                            }

                            @media screen and (max-width:500px) {
                                width: 100%;
                            }
                        }

                        .address {
                            display: flex;
                            gap: 15px;
                            border-left: 1px solid #dfdfdf;
                            padding-left: 15px;
                            flex-wrap: wrap;

                            @media screen and (max-width:989px) {
                                flex-direction: column;
                            }

                            @media screen and (max-width:672px) {
                                border: 0;
                                padding: 0;
                            }

                            @media screen and (max-width:500px) {
                                width: 100%;
                            }
                        }

                        @media screen and (max-width:767px) {
                            flex-wrap: wrap;
                        }
                    }

                    .credit-balance {
                        display: flex;
                        gap: 15px;
                        flex-wrap: wrap;

                        .p-field-checkbox {
                            align-items: center;
                            display: flex;
                            gap: 15px;
                        }
                    }

                    .RadioButton {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 15px;

                        .topay {
                            display: flex;
                            align-items: center;

                            .p-radiobutton {
                                &.p-highlight {
                                    .p-radiobutton-box {
                                        border-color: #E35959;
                                        background: #E35959;
                                    }
                                }

                                &:not(.p-disabled):has(.p-radiobutton-input:hover) {
                                    .p-radiobutton-box {
                                        border-color: #E35959;
                                    }
                                }
                            }
                        }

                        .toreceive {
                            display: flex;
                            align-items: center;

                            .p-radiobutton {
                                &.p-highlight {
                                    .p-radiobutton-box {
                                        border-color: #1FAF9D;
                                        background: #1FAF9D;
                                    }
                                }

                                &:not(.p-disabled):has(.p-radiobutton-input:hover) {
                                    .p-radiobutton-box {
                                        border-color: #1FAF9D;
                                    }
                                }
                            }

                        }
                    }

                    .credit-radio-button {
                        .custom-limit {
                            display: flex;
                            gap: 15px;

                            p {
                                margin: 0;
                            }
                        }

                        .p-float-label {
                            margin-top: 20px;

                            input {
                                max-width: 230px;
                            }
                        }
                    }
                }
            }
        }
    }

    .p-dialog-footer {
        box-shadow: 0px 0 8px 4px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin: 0;
        z-index: 1;

        .footer-save {
            Button {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width:1250px) {
        width: 90vw;
    }

    @media screen and (max-width: 767px) {
        width: 90vw;
    }
}

.p-tooltip {
    .p-tooltip-text {
        padding: 8px;
        font-size: 12px;
    }
}

.p-tieredmenu {
    overflow: hidden;
    padding: 0;

    .p-tieredmenu-root-list {
        .p-menuitem {
            .p-menuitem-content {
                .p-menuitem-link {
                    padding: 10px;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}