:root {
  --primary-Color: #FFFFFF;
  --primary-bg-color: #44495D;
  --prime-bg-color: #575d81e0;
  --box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #5f5f5f96, 0 1px 2px 0 rgb(0 0 0);
  --secondary-bg-color: #D5E0FF;
  --paid-color: #0F7D51;
  --unpaid-color: #F14E4D;
  --text-color: #111111;
  --sub-titel-text-color: #B3B3B3;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}

.board {
  margin-top: 15px;
  margin-left: calc(250px + 15px);
  margin-right: 15px;
  position: relative;
  transition: all 0.3s;

  &.DashboardOpen {
    margin-left: calc(250px + 15px);

    @media screen and (max-width:767px) {
      margin-left: 15px;
    }
  }

  &.DashboardColse {
    margin-left: calc(70px + 15px);

    @media screen and (max-width:767px) {
      margin-left: 15px;
    }
  }
}

// thermal print in dialog box  
#thermal-dialog {
  .p-dialog-content {
    padding-top: 10px;
  }
}


.p-float-label {

  textarea {
    resize: none;

    @media screen and (max-width:500px) {
      width: 100%;
    }
  }

  input:focus~label,
  input:-webkit-autofill~label,
  input.p-filled~label,
  textarea:focus~label,
  textarea.p-filled~label,
  .p-inputwrapper-focus~label,
  .p-inputwrapper-filled~label,
  .p-tooltip-target-wrapper~label,
  &:has(input:focus) label,
  &:has(input.p-filled) label,
  &:has(input:focus) label,
  &:has(input.p-filled) label,
  &:has(input:-webkit-autofill) label,
  &:has(textarea:focus) label,
  &:has(textarea.p-filled) label,
  &:has(.p-inputwrapper-focus) label,
  &:has(.p-inputwrapper-filled) label {
    top: -1% !important;
    font-size: 12px !important;
    padding: 2px 5px !important;
    background: #fff !important;
    width: auto !important;
    border-radius: 3px;
  }

  .p-inputnumber {
    width: 100%;
  }

  .p-multiselect {
    width: 100%;
  }

  @media screen and (max-width:500px) {
    width: 100%;
  }
}

// autocomplete background color dropdown
.p-autocomplete {
  .p-autocomplete-dropdown {
    background-color: var(--prime-bg-color) !important;
    border-color: var(--prime-bg-color) !important;

    &:focus {
      box-shadow: var(--box-shadow) !important;
    }
  }

  @media screen and (max-width:500px) {
    width: 100%;
  }
}

// switch button
.p-inputswitch {
  &.p-highlight {
    .p-inputswitch-slider {
      background-color: var(--prime-bg-color);
    }
  }
}

// check box 
.p-checkbox {
  &.p-highlight {
    .p-checkbox-box {
      background-color: var(--prime-bg-color) !important;
      border-color: var(--prime-bg-color) !important;
    }
  }

  &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: var(--prime-bg-color) !important;
  }
}

// Button
.p-button {
  &.btn-color {
    background-color: var(--prime-bg-color);
    border-color: var(--prime-bg-color);

    &:focus {
      box-shadow: var(--box-shadow);
    }
  }
}

//celeder
.p-datepicker-trigger {
  background-color: var(--prime-bg-color);
  border-color: var(--prime-bg-color);

  &:focus {
    box-shadow: var(--box-shadow);
  }
}

// information icon
.custom-target-icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: #eeeeee;
  padding: 5px;
  border-radius: 50%;
}

.p-inputtext {

  &:enabled {
    &:hover {
      box-shadow: none !important;
      border-color: #000000 !important;
    }
  }

  &:focus {
    border-color: #000000 !important;
    box-shadow: none !important;
  }

  &.p-invalid {
    border-color: #e24c4c !important;

    &:focus {
      border-color: #e24c4c !important;
    }

    &:enabled {
      &:hover {
        border-color: #e24c4c !important;
      }
    }
  }
}



.p-password {
  &.p-invalid {
    border-color: #e24c4c !important;

    .p-inputtext {

      &:focus {
        border-color: #e24c4c !important;
      }

      &:enabled {
        &:hover {
          border-color: #e24c4c !important;
        }
      }
    }
  }
}



// Table
.p-datatable {
  .p-datatable-wrapper {
    table {
      thead {
        tr {
          th {
            padding: 7px 10px;
            background-color: #fff;

            .p-column-header-content {
              // justify-content: flex-end;

              .p-column-title {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
              }

              .p-column-filter {
                .p-column-filter-menu-button {
                  height: 20px;
                  width: 20px;

                  &:hover {
                    background-color: #fff;
                    color: #000;
                  }

                  svg {
                    width: 10px;
                    height: 10px;
                  }
                }
              }

              span {
                svg {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          cursor: pointer;

          td {
            padding: 5px 10px;
            font-size: 14px;
            white-space: nowrap;

            .p-button-rounded {
              width: 1.5rem;
              height: 1.5rem;
              background: transparent;
              border: 1px solid transparent;

              &:hover {
                background-color: rgb(255 203 203);
              }

              &:focus {
                background-color: rgb(255 203 203);
              }

              .p-button-icon {
                color: red;
                font-size: 15px;
              }
            }

            &.p-cell-editing {
              padding: 0;

              .p-autocomplete {
                width: 100%;

                input {
                  // &:focus {
                  //   padding: 7px 10px !important;
                  // }
                }
              }
            }

            .p-inputtext {
              padding: 2px 4px;
              width: 100%;
            }

            &.action-button {
              width: 60px;

              .p-splitbutton {
                .p-splitbutton-defaultbutton {
                  display: none;
                }

                .p-splitbutton-menubutton {
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  background-color: transparent;
                  border-color: transparent;
                  transition: 0.3s ease all;

                  i {
                    font-size: 14px;
                    color: #000;
                  }

                  .p-button-label {
                    display: none;
                  }

                  &:hover {
                    background-color: #ccc;

                    i {
                      color: #000;
                    }
                  }
                }
              }
            }

            .p-dropdown {
              width: 100%;
              border: 0;
              background-color: transparent;

              .p-dropdown-trigger {
                justify-content: end;
              }

              &:not(.p-disabled):hover {
                border-color: #000 !important;
              }

              &:not(.p-disabled).p-focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem transparent !important;
                border: 1px solid #000 !important;
              }

            }

          }

          &:hover {
            background-color: #e6e6ea !important;
          }

          &:nth-child(odd) {
            background-color: #f2f2f2;
          }


        }
      }

      tfoot {
        tr {
          td {
            padding: 5px;

            .p-button {
              span {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

// multipal selection
.p-multiselect-panel {
  width: min-content;
}


//  Autocomplete

.p-autocomplete-panel {
  .p-autocomplete-items-wrapper {
    ul {
      li {
        padding: 5px 10px;
      }
    }
  }

}


// Split Button
.p-tieredmenu {
  padding: 4px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  outline: 0px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.9) !important;
  background-image: url(assets/img/cyan-blur.png), url(assets/img/red-blur.png) !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right top, left bottom !important;
  background-size: 50%, 50% !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px !important;
  border-radius: 6px !important;
  width: auto !important;
  overflow: inherit !important;
  margin-top: -6px !important;

  .p-tieredmenu-root-list {
    .p-menuitem {
      margin-bottom: 4px;

      .p-menuitem-link {
        padding: 10px;
        border-radius: 6px;

        &:focus {
          box-shadow: none !important;
        }

        .icon {
          width: 15px;
          height: 15px;
        }

        .pi-trash {
          color: #ff5630;
        }

        .p-menuitem-text {
          font-size: 14px;
          color: #212b36;
        }

        &:hover .p-menuitem-text {
          color: #212b36;
        }

        &:hover {
          text-decoration: none !important;
          background-color: #919eab14 !important;
        }
      }

      &:hover {
        color: #212b36 !important;
      }

      &:last-child {
        border-top: 1px dashed #dee2e6;
        margin-bottom: 0px;
        padding-top: 4px;

        .p-menuitem-text {
          color: #ff5630 !important;

          .active {
            color: #00a76f !important;
          }
        }
      }
    }
  }
}

// item selected dropdown option
.itemsOption {
  .p-autocomplete-items-wrapper {
    min-width: 700px !important;

    .p-autocomplete-items {
      .p-autocomplete-item {
        padding: 0;

        .saleSection {
          padding: 5px 10px;

          .itemheader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;

            .itemsSelection {
              width: 60%;
              overflow: hidden;
              text-overflow: ellipsis;

              .additems {
                display: flex;
                align-items: center;
                color: #6366f1;
                gap: 5px;
                font-size: 15px;

                i {
                  padding: 2px;
                  border: 1px solid #6366f1;
                  border-radius: 50%;
                  font-size: 11px;
                }
              }
            }

            .heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 40%;

              span {
                flex: 1;
                font-size: 14px;
                color: #696969;

                &:last-child {
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }
  }
}