#Reminder {
    button {
        box-shadow: none;
        background: transparent;
        border-color: transparent;
        color: #9198A7;
        width: auto;
        padding: 7px;
        border-radius: 50%;

        &:hover {
            background: #e6e6e6;
        }
    }
}

#Remainder {
    margin-top: 15px;

    .p-overlaypanel-content {
        padding: 15px;

        .card {
            h3 {
                margin-top: 0;
            }

            .contact {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .button {
                    display: flex;
                    justify-content: end;
                    gap: 15px;
                }
            }
        }
    }
}


.p-multiselect-panel {
    .p-multiselect-items-wrapper {
        .p-multiselect-items {
            .p-multiselect-item {
                span {
                    width: 100%;

                    .party_name {
                        display: flex;
                        justify-content: space-between;
                        gap: 15px;

                        span {
                            &:last-child {
                                text-align: end;
                                color: green;
                            }
                        }
                    }
                }
            }
        }
    }
}