.loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000000000;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 0.089);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 70px;
    }
}