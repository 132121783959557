.p-toast {
    width: 300px !important; 
    z-index: 1000001 !important;

    .p-toast-message {
        background: #ffffff !important;
        border-width: 0 0 0px 0 !important;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px !important;
        position: relative;
        overflow: hidden;

        &.p-toast-message-warning {
            background: #fff2e2;
            border: solid #cc8925;
            border-width: 0 0 0 6px;
            color: #cc8925;

            p {
                color: #000 !important;
            }

            .p-toast-icon-close {
                color: #cc8925;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 6px;
                background-color: #cc8925;
                animation: borderAnimation 1.5s linear 1 forwards;
                animation-play-state: running;
            }

            &:hover:after {
                animation-play-state: paused;
            }
        }

        &.p-toast-message-success {
            background: #e4f8f0;
            border: solid #1ea97c;
            border-width: 0 0 0 6px;
            color: #1ea97c;

            .p-toast-icon-close {
                color: #1ea97c;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 6px;
                background-color: #1ea97c;
                animation: borderAnimation 1.5s linear 1 forwards;
                animation-play-state: running;
            }

            &:hover:after {
                animation-play-state: paused;
            }
        }

        &.p-toast-message-error {
            background: #ffe7e6;
            border: solid #ff5757;
            border-width: 0 0 0 6px;
            color: #ff5757;

            .p-toast-icon-close {
                color: #ff5757;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 6px;
                background-color: #ff5757;
                animation: borderAnimation 2s linear 1 forwards;
                animation-play-state: running;
            }

            &:hover:after {
                animation-play-state: paused;
            }
        }

        &:last-child {
            margin: 0 0 0 0 !important;
        }

        .p-toast-message-content {
            padding: 10px !important;

            i {
                font-size: 24px !important;
                margin: auto;
            }

            .p-toast-message-text {
                margin: 0 0 0 10px !important;

                .p-toast-summary {
                    font-size: 15px !important;
                }

                .p-toast-detail {
                    font-size: 13px !important;
                    margin: 3px 0 0 0 !important;
                }
            }
        }
    }
}

@keyframes borderAnimation {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}