.page404 {

    .page404-img {
        width: 500px;
        height: 100%;
        margin: auto;

        img {
            width: 100%;
            height: 50vh;
        }

    }

    .page404-text {
        text-align: center;

        p:first-child {
            font-size: 50px;
            line-height: 0;
        }

        .page404-btn {

            margin-top: 1em;

        }
    }
}