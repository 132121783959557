#user-info-dialog {
    .p-dialog-header {
        padding: 15px;
        border-bottom: 1px solid #d1d5db;
    }

    .p-dialog-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        .p-field {
            display: flex;
            align-items: center;
            gap: 100px;
            justify-content: space-between;

            .p-autocomplete {
                width: 222px;
            }

            .image-preview {
                display: flex;
                gap: 15px;
                align-items: center;

                .p-image {
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
    }

    .p-dialog-footer {
        box-shadow: 0px 0 8px 4px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin: 0;
    }
}

