#Sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    box-shadow: 8px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-bg-color);
    color: #fff;
    z-index: 1000;
    transition: all 0.3s;

    &.sideBarOpen {
        width: 250px;

        .main {
            .company {
                .arrow {
                    right: -5%;
                }
            }
        }
    }

    &.sideBarClose {
        width: 70px;

        .main {
            .company {
                h2 {
                    display: none;
                }
            }

            .foter-title {
                display: none;
            }

            .menu {
                .p-panelmenu {
                    .p-panelmenu-panel {
                        .p-component {
                            .p-panelmenu-header-content {
                                a {
                                    // justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width:767px) {
            display: none;
        }
    }

    .Blacklayout {
        @media screen and (min-width: 0px) and (max-width: 767px) {
            position: fixed;
            top: 0;
            left: 250px;
            width: 100%;
            height: 100%;
            z-index: 20;
            background-color: rgba(22, 28, 36, 0.8);
        }
    }

    .main {
        .company {
            padding: 10px;
            border-bottom: 2px solid #E3E5EB40;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;
            cursor: pointer;
            position: relative;

            .image {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: 50px;
                justify-content: center;
                overflow: hidden;
                width: 50px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            h2 {
                font-size: 18px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;

                i {
                    font-size: 20px;
                    margin-left: 5px;
                    transition: 0.3s ease all;
                }

            }

            .arrow {
                position: absolute;
                top: 81.5%;
                right: -17%;
                z-index: 9999;
                padding: 4px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px dashed rgba(145, 158, 171, 0.2);
                background-color: #fff;
                cursor: pointer;

                i {
                    color: #212934;
                }

                @media screen and (max-width:767px) {
                    // display: none;
                }

            }

            &:hover {
                h2 {
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

        .menu {

            .card {
                height: calc(100vh - 115px);
                overflow: auto;
            }

            .p-panelmenu {
                .p-panelmenu-panel {
                    .p-component {
                        .p-panelmenu-header-content {
                            border: none;
                            color: var(--primary-Color);
                            border-left: 5px solid transparent;
                            background: transparent;
                            border-radius: 0px;
                            transition: box-shadow 0.2s;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            transition: 0.3s ease all;

                            a {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;

                                .text {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;

                                    .text-primary {
                                        width: 20px;
                                        height: 20px;
                                    }

                                    .pi {
                                        font-size: 16px;
                                        transition: 0.3s ease all;
                                        color: var(--primary-Color) !important;
                                    }

                                    h6 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: var(--primary-Color) !important;
                                    }
                                }

                                .pi {
                                    font-size: 14px;
                                    transition: 0.3s ease all;
                                    color: var(--primary-Color) !important;
                                }

                            }

                            &:hover {
                                color: #E6E7E8;
                                border-left: 5px solid #ED1A3B;
                                background-color: #00000029;

                                a {
                                    .text {

                                        .pi {
                                            color: #E6E7E8 !important;
                                        }
                                    }

                                    .pi {
                                        color: #E6E7E8 !important;
                                    }
                                }
                            }
                        }

                        &.p-highlight {
                            .p-panelmenu-header-content {
                                a {
                                    .pi-angle-down {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }

                    .p-toggleable-content {
                        .p-panelmenu-content {
                            padding: 0.5rem 0;
                            border: 1px solid transparent;
                            background: transparent;
                            color: #fff;
                            border-top: 0;
                            border-top-right-radius: 0;
                            border-top-left-radius: 0;
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0;

                            .p-panelmenu-root-list {
                                .p-menuitem {
                                    .p-menuitem-content {
                                        border: none;
                                        border-left: 5px solid transparent;
                                        color: var(--primary-Color);
                                        background: transparent;
                                        border-radius: 0px;
                                        transition: box-shadow 0.2s;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        transition: 0.3s ease all;

                                        a {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;

                                            .text {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;

                                                .text-primary {
                                                    width: 20px;
                                                    height: 20px;
                                                }

                                                .pi {
                                                    font-size: 16px;
                                                    transition: 0.3s ease all;
                                                    color: var(--primary-Color) !important;
                                                }

                                                h6 {
                                                    margin: 0;
                                                    font-size: 14px;
                                                    font-weight: 600;
                                                    color: var(--primary-Color);
                                                }
                                            }

                                            .pi {
                                                font-size: 14px;
                                                transition: 0.3s ease all;
                                                color: var(--primary-Color);

                                            }
                                        }

                                        &:hover {
                                            color: #E6E7E8;
                                            border-left: 5px solid #ED1A3B;
                                            background-color: #00000029;

                                            a {
                                                .text {

                                                    .pi {
                                                        color: #E6E7E8 !important;
                                                    }
                                                }

                                                .pi {
                                                    color: #E6E7E8 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.close {
                    .p-panelmenu-panel {
                        .p-component {
                            .p-panelmenu-header-content {
                                a {
                                    .text {
                                        h6 {
                                            display: none;
                                        }
                                    }

                                    i {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .p-toggleable-content {
                            .p-panelmenu-content {
                                .p-panelmenu-root-list {
                                    .p-menuitem {
                                        .p-menuitem-content {
                                            a {
                                                .text {
                                                    width: 5px;
                                                    height: 5px;
                                                    background-color: #637381;
                                                    border-radius: 50%;
                                                    margin: 5px;

                                                    h6 {
                                                        display: none;
                                                    }
                                                }

                                                i {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            }

        .foter-title {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            display: block;

            h5 {
                margin-bottom: 10px;
                cursor: pointer;
            }
        }
    }
}