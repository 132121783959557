.login-container {
    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.8s ease-in-out;

        .login-from {
            // height: calc(100% - 30px);

            .logo {
                width: 150px;
                margin: 15px;

                img {
                    width: 100%;
                    height: 100%;
                }

                @media screen and (max-width:767px) {
                    width: 100px;
                    margin: 0;
                }
            }

            .from-details {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 20px;

                .sign-text {
                    h1 {
                        color: #009ED8;

                        &.Sign_in {
                            @media screen and (min-width:0) and (max-width:767px) {
                                margin-bottom: 0;
                                font-size: 22px;
                                text-align: center;
                            }
                        }
                    }

                    p {
                        display: none;

                        span {
                            color: #00a76f;
                        }

                        @media screen and (min-width:0) and (max-width:767px) {
                            margin-top: 0;
                            display: block;
                            text-align: center;
                        }
                    }
                }

                .from {
                    gap: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    max-width: 350px;

                    .input {
                        .p-password {
                            width: 100%;
                            display: flex;
                            justify-content: end;

                            input {
                                padding: 12px 35px 12px 12px;
                            }

                            .p-icon {
                                right: 0.75rem;
                            }
                        }

                        .p-float-label {
                            max-width: 100%;

                            input {
                                width: 100%;
                            }
                        }
                    }

                    .ForgotPassword {
                        .Forgot {
                            text-decoration: underline;
                            cursor: pointer;
                            background-color: inherit;
                        }
                    }

                    .return-signin {
                        Button {
                            background-color: inherit;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }

                    Button {
                        width: 100%;
                        max-width: 200px;
                        border-radius: 40px;
                        margin-top: 15px;
                        background-color: #009ED8;
                        border: 1px solid transparent;
                    }
                }
            }
        }

        .foter-title {
            text-align: center;

            span {
                margin: 0;
                cursor: pointer;
            }
        }

        .from {
            gap: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 350px;

            &.register {
                .name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                }
            }

            .input {
                width: 100%;

                .p-password {
                    width: 100%;
                    display: flex;
                    justify-content: end;

                    input {
                        padding: 12px 35px 12px 12px;
                    }

                    .p-icon {
                        right: 0.75rem;
                    }
                }

                .p-inputnumber {
                    width: 100%;
                }

                .p-float-label {
                    max-width: 100%;

                    input {
                        width: 100%;
                    }
                }
            }

            .ForgotPassword {
                .Forgot {
                    text-decoration: underline;
                }
            }

            Button {
                width: 100%;
                max-width: 200px;
                border-radius: 40px;
                margin-top: 15px;
                background-color: #009ED8;
                border: 1px solid transparent;

                @media screen and (max-width:767px) {
                    margin: 0;
                }
            }
        }
    }

    .register-from {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // height: calc(100% - 30px);
        gap: 20px;
        margin-bottom: 20px;

        .logo {
            width: 155px;
            height: auto;

            img {
                height: 100%;
                width: 100%;
            }

            @media screen and (max-width:767px) {
                width: 100px;
            }
        }

        .otp-verification {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .sign-text {
            h1 {
                color: #009ED8;
                margin: 0;
                text-align: center;

                @media screen and (max-width:767px) {
                    font-size: 22px;
                }
            }

            p {
                margin: 0;
                display: none;

                span {
                    color: #00a76f;
                }

                @media screen and (min-width:0)and (max-width: 767px) {
                    display: block;
                }
            }
        }

        @media screen and (max-width:767px) {
            gap: 10px;
        }
    }

    .sign-up-container {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;

        &.login {
            @media screen and (min-width: 0) and (max-width:767px) {
                width: 100%;
                padding: 15px;
            }
        }
    }

    .sign-in-container {
        left: 0;
        width: 50%;
        z-index: 2;
        height: 100vh;
        overflow: auto;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .regis_from {
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media screen and (min-width: 0) and (max-width:767px) {
            display: none;
        }

        &.register_res {
            @media screen and (min-width: 0) and (max-width:767px) {
                display: flex;
                width: 100%;
                padding: 15px;
            }
        }
    }

    .overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.8s ease-in-out;
        z-index: 100;

        .overlay {
            background: -webkit-linear-gradient(to right, #009ED8, #005BA2);
            background: linear-gradient(to right, #009ED8, #005BA2);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            color: #ffffff;
            position: relative;
            left: -100%;
            height: 100%;
            width: 200%;
            transform: translateX(0);
            transition: transform 0.8s ease-in-out;

            .overlay-panel {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 0 40px;
                text-align: center;
                top: 0;
                height: 100%;
                width: 50%;
                transform: translateX(0);
                transition: transform 0.8s ease-in-out;

                .ghost {
                    border-radius: 30px;
                    border: 1px solid #38a3a2;
                    background-color: #38a3a2;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 15px 75px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    transition: transform 80ms ease-in;

                    &:active {
                        transform: scale(0.95);
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .ghost {
                    background-color: transparent;
                    border-color: #ffffff;
                }
            }

            .overlay-right {
                right: 0;
                transform: translateX(0);
            }
        }

        @media screen and (min-width: 0) and (max-width:767px) {
            display: none;
        }
    }

    &.container {
        &.right-panel-active {
            .sign-up-container {
                transform: translateX(100%);
                opacity: 1;
                z-index: 5;
                animation: show 0.8s;
                height: 100vh;
                overflow: auto;
                padding: 30px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (min-width: 0) and (max-width:767px) {
                    transform: translateX(0);
                }

                &.login_res {
                    @media screen and (min-width: 0) and (max-width:767px) {
                        display: none;
                    }
                }
            }

            .sign-in-container {
                transform: translateX(00%);
            }

            .overlay-container {
                transform: translateX(-100%);
            }

            .overlay {
                transform: translateX(50%);
            }

            .overlay-left {
                transform: translateX(0);
            }
        }
    }
}

@keyframes show {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

#meassge-box {
    .p-dialog-header {
        padding: 15px;
        border-bottom: 1px solid #eeeeee;
    }

    .p-dialog-content {
        padding: 15px;

        div {
            p {
                margin: 0;
            }
        }
    }
}