.notification-badge {
    position: absolute;
    top: 2px;
    right: 5px;
    background-color: #ff0000;
    color: #ffffff;
}

.OverlayPanel-notifictions {
    .p-overlaypanel-content {
        padding: 0;
        max-width: 350px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        .notifictions {
            font-family: 'Arial, sans-serif';

            .contain {
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .p-button {
                    background-color: #E3E5EB;
                    border-radius: 50%;
                    width: auto;
                    height: auto;
                    padding: 15px;
                    color: #9198A7;
                    border-color: transparent;

                    .pi {
                        font-size: 32px;
                    }
                }

                h6 {
                    font-size: 16px;
                    margin-bottom: 0;
                    margin-top: 15px;
                }

                p {
                    font-size: 14px;
                }
            }

            .Notification-title {
                padding: 15px;
                border-bottom: 1px solid #E3E5EB;
                margin: 0;
                font-size: 15px;
                font-weight: 400;
            }

            .message-board {
                padding: 15px;
                max-height: 400px;
                overflow-y: auto;
                background-color: #f9f9f9;
            }

            .message-card {
                display: flex;
                flex-direction: column;
                background-color: #fff; 
                border-radius: 8px;
                padding: 10px;
                margin-bottom: 15px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                cursor: pointer;

                &:hover {
                    background-color: #f4f4f4;
                }

                .message-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .message-info {
                        display: flex;
                        flex-direction: column;

                        .sender-name {
                            font-weight: bold;
                            color: #0ea5e9;
                        }

                        .timestamp {
                            font-size: 12px;
                            color: #888;
                        }
                    }
                }

                .message-content {
                    .message-title {
                        font-weight: bold;
                        color: #555;
                    }

                    p {
                        margin: 0;
                    }
                }

                .message-actions {
                    display: flex;
                    gap: 10px;

                    .action-btn {
                        padding: 5px 10px;
                        font-size: 14px;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &.accept {
                            background-color: #28a745;
                            color: #fff;

                            &:hover {
                                background-color: #218838;
                            }
                        }

                        &.decline {
                            background-color: #dc3545;
                            color: #fff;

                            &:hover {
                                background-color: #c82333;
                            }
                        }
                    }
                }
            }

            .no-messages {
                text-align: center;
                color: #aaa;
                font-size: 14px;
            }
        }
    }
}