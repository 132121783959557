#Header {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9;
    transition: all 0.3s;

    &.headerOpen {
        margin-left: 250px;
        width: calc(100% - 250px);

        @media screen and (max-width:767px) {
            margin-left: 15px;
            width: calc(100% - 30px);
        }
    }

    &.headerColse {
        margin-left: 85px;
        width: calc(100% - 100px);

        @media screen and (max-width:767px) {
            margin-left: 15px;
            width: calc(100% - 30px);
        }
    }

    .main {
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: space-between;

        .siderbarIcon {
            @media screen and (min-width:768px) {
                display: none;
            }
        }

        .leftHeader {
            .p-inputgroup {
                .p-inputgroup-addon {
                    border: 0px solid;
                    background-color: transparent;
                    padding-right: 0px;
                }

                .p-inputtext {
                    border: 0px solid;

                    &:focus,
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }

        .rightHeader {
            display: flex;
            align-items: center;
            gap: 10px;

            .button {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 15px;
                padding-right: 15px;
                border-right: 2px solid #E3E5EB;

                .add_sale {
                    // border-color: var(--paid-color) !important;

                    // span {
                    //     color: var(--paid-color) !important;
                    // }

                }

                .add_purchase {
                    // border-color: var(--unpaid-color) !important;

                    // span {
                    //     color: var(--unpaid-color) !important;
                    // }
                }

                .add_more {
                    @media screen and (max-width:850px) {
                        display: none;
                    }
                }

                @media screen and (max-width:767px) {
                    display: none;
                }
            }

            .fullscrin_icons {
                .p-button {
                    box-shadow: none;
                    background: transparent;
                    border-color: transparent;
                    color: #9198A7;
                    padding: 0px;
                    width: auto;
                    padding: 7px;
                    border-radius: 50%;

                    &:hover {
                        background: #e6e6e6;
                    }

                    .pi {
                        font-size: 20px;
                    }

                    &:focus,
                    &:hover {
                        box-shadow: none;
                    }
                }
            }

            .icons {
                display: flex;
                justify-content: center;
                gap: 15px;

                .p-button {
                    box-shadow: none;
                    background: transparent;
                    border-color: transparent;
                    color: #9198A7;
                    padding: 0px;
                    width: auto;
                    padding: 7px;
                    border-radius: 50%;

                    &:hover {
                        background: #e6e6e6;
                    }

                    .pi {
                        font-size: 20px;
                    }

                    &:focus,
                    &:hover {
                        box-shadow: none;
                    }
                }

                .letter_word_click {
                    .letter_word {
                        background-color: #f3492f;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        // box-shadow: 2px 2px 20px #ccc;
                        color: #fff;
                        cursor: pointer;

                        h2 {
                            text-transform: capitalize;
                        }

                        &.open {
                            &::after {
                                border: 2px solid #00a76f;
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            border: 1px solid #ccc;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 0;
                        }

                        &:hover {
                            &::after {
                                border: 2px solid #00a76f;
                            }

                        }

                    } 

                    .toggleBar {
                        position: absolute;
                        top: 80%;
                        right: 12px;
                        background-color: #fff;
                        border-radius: 7px;
                        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
                        color: #000;
                        width: auto;

                        .nameBar {
                            padding: 15px;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px dashed #ccc;
                            cursor: default;

                            .letter_word2 {
                                background-color: #f3492f;
                                border-radius: 50%;
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                color: #fff;

                                h2 {
                                    text-transform: capitalize;
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    border: 2px solid #00a76f;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 0;
                                }
                            }

                            .username {
                                margin-left: 15px;
                            }

                            h6 {
                                margin: 0;
                                font-size: 15px;
                                word-break: break-all;
                                width: max-content;
                            }

                            svg {
                                margin-right: 5px;
                            }

                            p {
                                font-size: 12px;
                                margin: 0px;
                            }
                        }

                        .navigation {
                            ul {
                                list-style: none;
                                padding: 8px;
                                margin: 0;
                                color: #212b36;

                                li {
                                    cursor: pointer;
                                    padding: 10px;
                                    font-size: 15px;
                                    border-radius: 6px;
                                    margin-bottom: 4px;

                                    a {
                                        text-decoration: none;
                                        color: #000;
                                    }

                                    &:last-child {
                                        border-bottom-left-radius: 7px;
                                        border-bottom-right-radius: 7px;
                                    }

                                    &:hover {
                                        background-color: rgba(145, 158, 171, 0.08);
                                    }

                                    &.hozaderline {
                                        padding: 0 !important;
                                        border-top: 1px dashed #ccc;
                                    }
                                }
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: -4%;
                            right: 3%;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid #fff;
                            z-index: 1;
                        }
                    }
                }
            }


        }
    }
}

#Support_icons {
    width: 360px;

    .p-dialog-header {
        padding: 15px;
        border-bottom: 1px solid #eeeeee;
    }

    .p-dialog-content {
        padding: 15px;
    }

    .p-dialog-footer {
        box-shadow: 0px 0 8px 4px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin: 0;
    }
}

.p-overlaypanel {
    .p-overlaypanel-content {
        padding: 0;

        .addmore {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .addmore-box {
                display: flex;
                gap: 30px;
                justify-content: space-between;
                padding: 15px;

                div {

                    h4,
                    p {
                        margin: 0;
                    }
                }

                .sale {
                    .sale-box {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        margin-top: 15px;

                        .sale-line {
                            display: flex;
                            gap: 15px;
                            cursor: pointer;
                            border-bottom: 2px solid #fff;
                            justify-content: space-between;

                            .sale-icon {
                                display: flex;
                                gap: 7px;

                                i {
                                    color: #0075E8;
                                }
                            }

                            .key {
                                color: #a5a5a5;
                            }

                            &:hover {
                                border-bottom: 2px solid #0075E8;
                                color: #0075E8;
                            }
                        }
                    }
                }
            }

            .footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                padding: 10px;
                background-color: #FFE494;
                width: 100%;

                .short-cut {
                    color: #a27314;
                }
            }
        }
    }
}