@font-face {
  font-family: "Poppins", sans-serif;
  src: url('assets/Fonts/Poppins-Regular.ttf') format('truetype');
}
 

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
  
* {
  font-family: "Poppins", sans-serif;
}
